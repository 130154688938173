body {
	position: relative;
	display: flex;
    flex-direction: column;
	width: 100vw;
	margin: 0;
	background-color: #222222;
	font: normal 400 16px / 26px "SF Pro Text", sans-serif;
    color: #B9B9B9;
	cursor: default;

    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        height: 0;
        width: 2px;
        
        &-track {
            background: transparent;
        }
        
        &-button {
            display: none;
        }
        
        &-thumb {
            background: linear-gradient(to bottom, $color-primary, #222222);;
        }
    }

    &.show-menu {
        overflow: hidden;

        .header {
			&__menu {
                transform: none;
			}
		}
    }

	* {
		box-sizing: border-box;
		margin: 0;
	}

    img, svg {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;

        path {
            transition: fill 0.3s;
        }
    }

    iframe, video {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }

    a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
    }

    .disabled,
    .disabled * {
        pointer-events: none;
        cursor: not-allowed;
    }
}