.formarea {
    input {
        border: none;
        outline: none;
        appearance: none;
        font-family: "SF Pro Display", sans-serif;

        &::placeholder {
			color: #D6D6D6;
		}
    }

    &__input {
        display: block;
        width: 100%;
        margin: 0;
        padding: 13px 0;
        background-color: transparent;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #FFFFFF;
        text-align: center;
        transition: border-color 0.3s;
    
        @include is-screen-max($breakpoint-sm) {
            font-size: 14px;
            line-height: 22px;
        }

        &.error {
            color: #E04748;

            &::placeholder {
                color: #E04748;
            }

            & + .formarea__divider {
                background-color: #E04748;
            }
        }
    }
    
    &__divider {
        display: block;
        height: 2px;
        border-radius: 5px;
        background-color: #404040;
        transition: background-color 0.3s;
    
        @include is-screen-max($breakpoint-sm) {
            height: 1px;
        }
    }
    
    &__submit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 70px 0 0;
        padding: 17px;
        border-radius: 17px;
        background-color: $color-primary;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: #222222;
        transition: opacity 0.3s;
        cursor: pointer;
    
        @include is-screen-max($breakpoint-sm) {
            margin-top: 45px;
            padding: 15px;
            border-radius: 12px;
            font-size: 17px;
            line-height: 22px;
        }

        &:hover {
            @include is-screen-min($breakpoint-md) {
                opacity: 0.6;
            }
        }
    }

	&__response {
		margin-top: 30px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
		color: #34C167;
        text-align: center;

        &.hide {
            display: none;
        }

		&.error {
			color: #E04748;
		}

        @include is-screen-max($breakpoint-sm) {
            margin-top: 20px;
            font-size: 14px;
            line-height: 22px;
        }
	}
}