.footer {
	position: relative;
	margin-top: -600px;
	padding-top: 700px;
	padding-bottom: 80px;
	overflow: hidden;

	@include is-screen-max($breakpoint-sm) {
		padding-top: 625px;
	}

	&::before {
		content: "";
		position: absolute;
		bottom: -25px;
		left: 50%;
		transform: translateX(-50%);
		width: 200vw;
		height: 780px;
		background-color: #222222;
		filter: blur(60px);
		z-index: -1;

		@include is-screen-min($breakpoint-sm) {
			display: none;
		}
	}
	
	&__bg {
		position: absolute;
		bottom: -488px;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		max-width: initial;
		max-height: initial;
		z-index: -1;
	
		@include is-screen-max($breakpoint-xxl) {
			width: auto;
			height: 980px;
		}
	
		@include is-screen-max($breakpoint-sm) {
			bottom: 0;
			transform: translateX(calc(-50% + 25px));
			height: auto;
			width: 640px;
			min-width: calc(100vw + 50px);
		}
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__logo {
		transition: opacity 0.3s;

		&:hover {
			@include is-screen-min($breakpoint-sm) {
				opacity: 0.6;
			}
		}

		img {
			height: 60px;

			@include is-screen-max($breakpoint-lg) {
				height: 45px;
			}

			@include is-screen-max($breakpoint-sm) {
				height: 35px;
			}
		}
	}

	&__email {
		position: absolute;
		top: 15px;
		right: 110px;
		font-family: "SF Pro Display", sans-serif;
		font-weight: 700;
		font-size: 20px;
		line-height: 100%;
		color: #FFFFFF;
		transition: opacity 0.3s;

		@include is-screen-max($breakpoint-lg) {
			right: 80px;
			font-size: 18px;
		}
	
		@include is-screen-max($breakpoint-sm) {
			position: relative;
			top: initial;
			right: initial;
			margin-bottom: 25px;
			font-size: 15px;
			line-height: 200%;
			order: -1;
		}

		&:hover {
			@include is-screen-min($breakpoint-sm) {
				opacity: 0.6;
			}
		}
	}
}