.header {
	&__burger {
		position: fixed;
		top: 100px;
		left: 110px;
		width: 24px;
		transition: opacity 0.3s;
		cursor: pointer;
		z-index: 50;

		@include is-screen-max($breakpoint-sm) {
			top: 30px;
			left: 30px;
			width: 20px;
		}
		
		&:hover {
			@include is-screen-min($breakpoint-sm) {
				opacity: 0.6;
			}
		}
	}

	.menu {
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		transform: translateX(-100%);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 60px;
		width: 520px;
		height: 100vh;
		padding: 190px 40px 100px 110px;
		background-color: #313131;
		transition: transform 0.3s;
		z-index: 40;

		@include is-screen-max($breakpoint-sm) {
			justify-content: center;
			align-items: center;
			width: 100%;
			padding: 40px 40px 80px;
		}

		&__item {
			max-width: 230px;
			font-family: "SF Pro Display", sans-serif;
			font-weight: 900;
			font-size: 25px;
			line-height: 30px;
			color: #FFFFFF;
			text-transform: uppercase;
			transition: color 0.3s;
	
			@include is-screen-max($breakpoint-sm) {
				text-align: center;
			}

			&:hover {
				@include is-screen-min($breakpoint-sm) {
					color: $color-primary;
				}
			}
		}
	}
}