$types: "UltraLight" 100, "Thin" 200, "Light" 300, "Regular" 400, "Medium" 500, "SemiBold" 600, "Bold" 700, "Heavy" 800, "Black" 900;

@each $type, $weight in $types {
    @font-face {
        font-family: "SF Pro Text";
        src: url("../fonts/SF Pro Text/SF-Pro-Text-#{$type}.otf") format("opentype");
        font-weight: $weight;
        font-style: normal;
    }

    @font-face {
        font-family: "SF Pro Display";
        src: url("../fonts/SF Pro Display/SF-Pro-Display-#{$type}.otf") format("opentype");
        font-weight: $weight;
        font-style: normal;
    }
}