@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-UltraLight.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-UltraLight.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-Thin.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-Thin.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF Pro Text/SF-Pro-Text-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF Pro Display/SF-Pro-Display-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0;
  background-color: #222222;
  font: normal 400 16px/26px "SF Pro Text", sans-serif;
  color: #B9B9B9;
  cursor: default;
  overflow-y: auto;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  height: 0;
  width: 2px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-button {
  display: none;
}
body::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #FFFF00, #222222);
}
body.show-menu {
  overflow: hidden;
}
body.show-menu .header__menu {
  transform: none;
}
body * {
  box-sizing: border-box;
  margin: 0;
}
body img, body svg {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
body img path, body svg path {
  transition: fill 0.3s;
}
body iframe, body video {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
body a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
body .disabled,
body .disabled * {
  pointer-events: none;
  cursor: not-allowed;
}

.header__burger {
  position: fixed;
  top: 100px;
  left: 110px;
  width: 24px;
  transition: opacity 0.3s;
  cursor: pointer;
  z-index: 50;
}
@media screen and (max-width: 767.9px) {
  .header__burger {
    top: 30px;
    left: 30px;
    width: 20px;
  }
}
@media screen and (min-width: 768px) {
  .header__burger:hover {
    opacity: 0.6;
  }
}
.header .menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;
  width: 520px;
  height: 100vh;
  padding: 190px 40px 100px 110px;
  background-color: #313131;
  transition: transform 0.3s;
  z-index: 40;
}
@media screen and (max-width: 767.9px) {
  .header .menu {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 40px 80px;
  }
}
.header .menu__item {
  max-width: 230px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 900;
  font-size: 25px;
  line-height: 30px;
  color: #FFFFFF;
  text-transform: uppercase;
  transition: color 0.3s;
}
@media screen and (max-width: 767.9px) {
  .header .menu__item {
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .header .menu__item:hover {
    color: #FFFF00;
  }
}

.footer {
  position: relative;
  margin-top: -600px;
  padding-top: 700px;
  padding-bottom: 80px;
  overflow: hidden;
}
@media screen and (max-width: 767.9px) {
  .footer {
    padding-top: 625px;
  }
}
.footer::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 200vw;
  height: 780px;
  background-color: #222222;
  filter: blur(60px);
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .footer::before {
    display: none;
  }
}
.footer__bg {
  position: absolute;
  bottom: -488px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: initial;
  max-height: initial;
  z-index: -1;
}
@media screen and (max-width: 1919.9px) {
  .footer__bg {
    width: auto;
    height: 980px;
  }
}
@media screen and (max-width: 767.9px) {
  .footer__bg {
    bottom: 0;
    transform: translateX(calc(-50% + 25px));
    height: auto;
    width: 640px;
    min-width: calc(100vw + 50px);
  }
}
.footer__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer__logo {
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  .footer__logo:hover {
    opacity: 0.6;
  }
}
.footer__logo img {
  height: 60px;
}
@media screen and (max-width: 1239.9px) {
  .footer__logo img {
    height: 45px;
  }
}
@media screen and (max-width: 767.9px) {
  .footer__logo img {
    height: 35px;
  }
}
.footer__email {
  position: absolute;
  top: 15px;
  right: 110px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #FFFFFF;
  transition: opacity 0.3s;
}
@media screen and (max-width: 1239.9px) {
  .footer__email {
    right: 80px;
    font-size: 18px;
  }
}
@media screen and (max-width: 767.9px) {
  .footer__email {
    position: relative;
    top: initial;
    right: initial;
    margin-bottom: 25px;
    font-size: 15px;
    line-height: 200%;
    order: -1;
  }
}
@media screen and (min-width: 768px) {
  .footer__email:hover {
    opacity: 0.6;
  }
}

.formarea input {
  border: none;
  outline: none;
  appearance: none;
  font-family: "SF Pro Display", sans-serif;
}
.formarea input::placeholder {
  color: #D6D6D6;
}
.formarea__input {
  display: block;
  width: 100%;
  margin: 0;
  padding: 13px 0;
  background-color: transparent;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #FFFFFF;
  text-align: center;
  transition: border-color 0.3s;
}
@media screen and (max-width: 767.9px) {
  .formarea__input {
    font-size: 14px;
    line-height: 22px;
  }
}
.formarea__input.error {
  color: #E04748;
}
.formarea__input.error::placeholder {
  color: #E04748;
}
.formarea__input.error + .formarea__divider {
  background-color: #E04748;
}
.formarea__divider {
  display: block;
  height: 2px;
  border-radius: 5px;
  background-color: #404040;
  transition: background-color 0.3s;
}
@media screen and (max-width: 767.9px) {
  .formarea__divider {
    height: 1px;
  }
}
.formarea__submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 70px 0 0;
  padding: 17px;
  border-radius: 17px;
  background-color: #FFFF00;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #222222;
  transition: opacity 0.3s;
  cursor: pointer;
}
@media screen and (max-width: 767.9px) {
  .formarea__submit {
    margin-top: 45px;
    padding: 15px;
    border-radius: 12px;
    font-size: 17px;
    line-height: 22px;
  }
}
@media screen and (min-width: 1024px) {
  .formarea__submit:hover {
    opacity: 0.6;
  }
}
.formarea__response {
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #34C167;
  text-align: center;
}
.formarea__response.hide {
  display: none;
}
.formarea__response.error {
  color: #E04748;
}
@media screen and (max-width: 767.9px) {
  .formarea__response {
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
  }
}

.page-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}
.page-home__subtitle {
  margin-bottom: 50px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 900;
  font-size: 70px;
  line-height: 100%;
  color: #FFFF00;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 1239.9px) {
  .page-home__subtitle {
    margin-bottom: 40px;
    font-size: 60px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home__subtitle {
    margin-bottom: 30px;
    font-size: 35px;
  }
}
.page-home .banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 800px;
  padding-top: 95px;
  margin-bottom: 80px;
  overflow: hidden;
}
@media screen and (max-width: 1239.9px) {
  .page-home .banner {
    height: 600px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .banner {
    height: 500px;
    padding-top: 75px;
    margin-bottom: 60px;
  }
}
.page-home .banner::after {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 196px;
  border-radius: 5px;
  background: linear-gradient(to bottom, #FFFF00, #222222);
}
@media screen and (max-width: 1239.9px) {
  .page-home .banner::after {
    bottom: 0;
    height: 160px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .banner::after {
    width: 4px;
    height: 148px;
  }
}
.page-home .banner__bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: initial;
  max-height: initial;
  z-index: -1;
}
@media screen and (max-width: 1919.9px) {
  .page-home .banner__bg {
    width: 1920px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .banner__bg {
    width: 870px;
  }
}
.page-home .banner__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(100% - 80px);
}
@media screen and (max-width: 767.9px) {
  .page-home .banner__content {
    max-width: calc(100% - 10px);
  }
}
.page-home .banner__logo {
  padding: 11px 35px;
}
@media screen and (max-width: 1239.9px) {
  .page-home .banner__logo {
    padding: 0;
  }
}
.page-home .banner__logo img {
  height: 60px;
}
@media screen and (max-width: 767.9px) {
  .page-home .banner__logo img {
    height: 45px;
  }
}
.page-home .banner__title {
  margin-top: 160px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 900;
  font-size: 160px;
  line-height: 100%;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 1439.9px) {
  .page-home .banner__title {
    font-size: 120px;
  }
}
@media screen and (max-width: 1239.9px) {
  .page-home .banner__title {
    margin-top: 100px;
    font-size: 110px;
  }
}
@media screen and (max-width: 1023.9px) {
  .page-home .banner__title {
    max-width: 450px;
    margin-top: 90px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .banner__title {
    margin-top: 55px;
    font-size: 75px;
  }
}
.page-home .banner__title span {
  color: #FFFF00;
}
.page-home .about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1256px;
  max-width: calc(100% - 40px);
  margin-bottom: 80px;
}
@media screen and (max-width: 767.9px) {
  .page-home .about {
    max-width: calc(100% - 20px);
    margin-bottom: 50px;
  }
}
.page-home .about__content {
  display: flex;
  align-items: flex-start;
  gap: 64px;
  width: 100%;
  padding: 56px 56px 64px;
  border-radius: 120px;
  background: linear-gradient(180deg, #313131 0%, rgb(46, 46, 46) 100%);
}
@media screen and (max-width: 1439.9px) {
  .page-home .about__content {
    gap: 40px;
  }
}
@media screen and (max-width: 1239.9px) {
  .page-home .about__content {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 60px 40px;
    border-radius: 60px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .about__content {
    gap: 30px;
    padding: 0;
    border-radius: 0;
    background: transparent;
  }
}
.page-home .about__photo {
  flex-shrink: 0;
  width: 448px;
  max-width: 100%;
  border-radius: 80px;
  overflow: hidden;
}
@media screen and (max-width: 1439.9px) {
  .page-home .about__photo {
    width: 400px;
  }
}
@media screen and (max-width: 1239.9px) {
  .page-home .about__photo {
    max-width: calc(100% - 20px);
    border-radius: 60px;
  }
}
.page-home .about__text {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 32px;
}
@media screen and (max-width: 1239.9px) {
  .page-home .about__text {
    gap: 30px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .about__text {
    gap: 0;
    width: 420px;
    max-width: 100%;
  }
}
.page-home .about__text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #B9B9B9;
}
@media screen and (max-width: 767.9px) {
  .page-home .about__text p {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 28px 20px 24px;
    margin: 0 10px;
    border-radius: 60px;
    background: linear-gradient(180deg, #2E2E2E 0%, rgb(46, 46, 46) 100%);
    font-size: 13px;
    line-height: 24px;
    text-align: center;
  }
}
.page-home .about__text p span {
  font-weight: 700;
  color: #FFFF00;
}
@media screen and (max-width: 767.9px) {
  .page-home .about__text p span {
    display: block;
    padding: 0 20px;
    font-size: 28px;
    line-height: 110%;
  }
}
.page-home .about__text .slick-dots {
  display: flex;
  justify-content: center;
  gap: 7px;
  margin-top: 20px;
  padding: 0;
  list-style: none;
}
.page-home .about__text .slick-dots li {
  width: 16px;
  height: 2px;
  border-radius: 5px;
  background-color: #B9B9B9;
  transition: background-color 0.3s;
}
.page-home .about__text .slick-dots li.slick-active {
  background-color: #FFFF00;
}
.page-home .services {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 120px;
}
@media screen and (max-width: 1239.9px) {
  .page-home .services {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .services {
    margin-bottom: 60px;
  }
}
.page-home .services__content {
  display: flex;
  gap: 40px;
  width: 1360px;
  max-width: 100vw;
  margin-top: -10px;
  padding: 0 20px;
  overflow: auto hidden;
}
@media screen and (max-width: 767.9px) {
  .page-home .services__content {
    gap: 20px;
    width: 1100px;
  }
}
.page-home .services__content::-webkit-scrollbar {
  display: none;
}
.page-home .services__content .service {
  position: relative;
  flex-shrink: 0;
  width: 300px;
  min-height: 420px;
  padding: 50px 40px 50px 35px;
  border-radius: 60px;
  background-color: #313131;
  overflow: hidden;
  z-index: 10;
}
@media screen and (max-width: 767.9px) {
  .page-home .services__content .service {
    width: 260px;
    min-height: 350px;
    padding: 40px 35px 40px 30px;
    border-radius: 50px;
  }
}
.page-home .services__content .service::before {
  content: "";
  position: absolute;
  top: 55px;
  left: 0;
  width: 1.2px;
  height: 84px;
  border-radius: 5px;
}
@media screen and (max-width: 767.9px) {
  .page-home .services__content .service::before {
    top: 45px;
    height: 70px;
  }
}
.page-home .services__content .service__name {
  min-height: 35px;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 25px;
  line-height: 26px;
  letter-spacing: -0.32px;
  color: #FFFFFF;
  text-transform: uppercase;
}
@media screen and (max-width: 767.9px) {
  .page-home .services__content .service__name {
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 22px;
    letter-spacing: -0.27px;
  }
}
.page-home .services__content .service__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.32px;
  color: #B9B9B9;
}
@media screen and (max-width: 767.9px) {
  .page-home .services__content .service__text {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.27px;
  }
}
.page-home .services__content .service__bg {
  position: absolute;
  bottom: -87px;
  height: 328px;
  max-width: initial;
  max-height: initial;
  z-index: -1;
}
@media screen and (max-width: 767.9px) {
  .page-home .services__content .service__bg {
    bottom: -74px;
    height: 280px;
  }
}
.page-home .services__content .service:nth-child(1) .service__bg {
  right: -94px;
}
@media screen and (max-width: 767.9px) {
  .page-home .services__content .service:nth-child(1) .service__bg {
    left: -28px;
    right: initial;
  }
}
.page-home .services__content .service:nth-child(2) .service__bg {
  right: 0;
}
.page-home .services__content .service:nth-child(3) .service__bg {
  left: -127px;
}
@media screen and (max-width: 767.9px) {
  .page-home .services__content .service:nth-child(3) .service__bg {
    left: -108px;
  }
}
.page-home .services__content .service:nth-child(4) .service__bg {
  left: 0;
}
.page-home .services__content .service:nth-child(1)::before {
  background: linear-gradient(to bottom, #00FF9A, #262626);
}
.page-home .services__content .service:nth-child(2)::before {
  background: linear-gradient(to bottom, #ECFF00, #262626);
}
.page-home .services__content .service:nth-child(3)::before {
  background: linear-gradient(to bottom, #DC00FF, #262626);
}
.page-home .services__content .service:nth-child(4)::before {
  background: linear-gradient(to bottom, #0066FF, #262626);
}
.page-home .services__bg {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: initial;
  max-height: initial;
  z-index: -1;
}
@media screen and (max-width: 1919.9px) {
  .page-home .services__bg {
    width: 1920px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .services__bg {
    top: 280px;
    transform: translateX(calc(-50% + 25px));
    width: 640px;
    min-width: calc(100vw + 50px);
  }
}
.page-home .contacts {
  width: 570px;
  max-width: calc(100% - 80px);
}
@media screen and (max-width: 767.9px) {
  .page-home .contacts {
    width: 400px;
  }
}
.page-home .contacts .formarea {
  margin-top: -10px;
}
@media screen and (max-width: 767.9px) {
  .page-home .contacts .formarea {
    margin-top: 0;
  }
}