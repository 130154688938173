.page-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    z-index: 10;

    &__subtitle {
        margin-bottom: 50px;
        font-family: "SF Pro Display", sans-serif;
        font-weight: 900;
        font-size: 70px;
        line-height: 100%;
        color: $color-primary;
        text-transform: uppercase;
        text-align: center;

        @include is-screen-max($breakpoint-lg) {
            margin-bottom: 40px;
            font-size: 60px;
        }

        @include is-screen-max($breakpoint-sm) {
            margin-bottom: 30px;
            font-size: 35px;
        }
    }
    
    .banner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 800px;
        padding-top: 95px;
        margin-bottom: 80px;
        overflow: hidden;
        
        @include is-screen-max($breakpoint-lg) {
            height: 600px;
        }
    
        @include is-screen-max($breakpoint-sm) {
            height: 500px;
            padding-top: 75px;
            margin-bottom: 60px;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 6px;
            height: 196px;
            border-radius: 5px;
            background: linear-gradient(to bottom, #FFFF00, #222222);
        
            @include is-screen-max($breakpoint-lg) {
                bottom: 0;
                height: 160px;
            }
        
            @include is-screen-max($breakpoint-sm) {
                width: 4px;
                height: 148px;
            }
        }
        
        &__bg {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            max-width: initial;
            max-height: initial;
            z-index: -1;
        
            @include is-screen-max($breakpoint-xxl) {
                width: 1920px;
            }
        
            @include is-screen-max($breakpoint-sm) {
                width: 870px;
            }
        }
    
        &__content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: calc(100% - 80px);

            @include is-screen-max($breakpoint-sm) {
                max-width: calc(100% - 10px);
            }
        }
    
        &__logo {
            padding: 11px 35px;

            @include is-screen-max($breakpoint-lg) {
                padding: 0;
            }
    
            img {
                height: 60px;
    
                @include is-screen-max($breakpoint-sm) {
                    height: 45px;
                }
            }
        }
    
        &__title {
            margin-top: 160px;
            font-family: "SF Pro Display", sans-serif;
            font-weight: 900;
            font-size: 160px;
            line-height: 100%;
            color: #FFFFFF;
            text-transform: uppercase;
            text-align: center;

            @include is-screen-max($breakpoint-xl) {
                font-size: 120px;
            }

            @include is-screen-max($breakpoint-lg) {
                margin-top: 100px;
                font-size: 110px;
            }

            @include is-screen-max($breakpoint-md) {
                max-width: 450px;
                margin-top: 90px;
            }

            @include is-screen-max($breakpoint-sm) {
                margin-top: 55px;
                font-size: 75px;
            }

            span {
                color: $color-primary;
            }
        }
    }

    .about {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1256px;
        max-width: calc(100% - 40px);
        margin-bottom: 80px;

        @include is-screen-max($breakpoint-sm) {
            max-width: calc(100% - 20px);
            margin-bottom: 50px;
        }

        &__content {
            display: flex;
            align-items: flex-start;
            gap: 64px;
            width: 100%;
            padding: 56px 56px 64px;
            border-radius: 120px;
            background: linear-gradient(180deg, #313131 0%, rgb(46, 46, 46) 100%);

            @include is-screen-max($breakpoint-xl) {
                gap: 40px;
            }

            @include is-screen-max($breakpoint-lg) {
                flex-direction: column;
                align-items: center;
                gap: 60px;
                padding: 60px 40px;
                border-radius: 60px;
            }
    
            @include is-screen-max($breakpoint-sm) {
                gap: 30px;
                padding: 0;
                border-radius: 0;
                background: transparent;
            }
        }

        &__photo {
            flex-shrink: 0;
            width: 448px;
            max-width: 100%;
            border-radius: 80px;
            overflow: hidden;

            @include is-screen-max($breakpoint-xl) {
                width: 400px;
            }

            @include is-screen-max($breakpoint-lg) {
                max-width: calc(100% - 20px);
                border-radius: 60px;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-self: center;
            gap: 32px;

            @include is-screen-max($breakpoint-lg) {
                gap: 30px;
            }
    
            @include is-screen-max($breakpoint-sm) {
                gap: 0;
                width: 420px;
                max-width: 100%;
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.32px;
                color: #B9B9B9;
        
                @include is-screen-max($breakpoint-sm) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    padding: 28px 20px 24px;
                    margin: 0 10px;
                    border-radius: 60px;
                    background: linear-gradient(180deg, #2E2E2E 0%, rgb(46, 46, 46) 100%);
                    font-size: 13px;
                    line-height: 24px;
                    text-align: center;
                }

                span {
                    font-weight: 700;
                    color: $color-primary;
            
                    @include is-screen-max($breakpoint-sm) {
                        display: block;
                        padding: 0 20px;
                        font-size: 28px;
                        line-height: 110%;
                    }
                }
            }

            .slick-dots {
                display: flex;
                justify-content: center;
                gap: 7px;
                margin-top: 20px;
                padding: 0;
                list-style: none;

                li {
                    width: 16px;
                    height: 2px;
                    border-radius: 5px;
                    background-color: #B9B9B9;
                    transition: background-color 0.3s;

                    &.slick-active {
                        background-color: $color-primary;
                    }
                }
            }
        }
    }

    .services {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 120px;
                
        @include is-screen-max($breakpoint-lg) {
            margin-bottom: 80px;
        }
    
        @include is-screen-max($breakpoint-sm) {
            margin-bottom: 60px;
        }

        &__content {
            display: flex;
            gap: 40px;
            width: 1360px;
            max-width: 100vw;
            margin-top: -10px;
            padding: 0 20px;
            overflow: auto hidden;
        
            @include is-screen-max($breakpoint-sm) {
                gap: 20px;
                width: 1100px;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .service {
                position: relative;
                flex-shrink: 0;
                width: 300px;
                min-height: 420px;
                padding: 50px 40px 50px 35px;
                border-radius: 60px;
                background-color: #313131;
                overflow: hidden;
                z-index: 10;
            
                @include is-screen-max($breakpoint-sm) {
                    width: 260px;
                    min-height: 350px;
                    padding: 40px 35px 40px 30px;
                    border-radius: 50px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 55px;
                    left: 0;
                    width: 1.2px;
                    height: 84px;
                    border-radius: 5px;
                
                    @include is-screen-max($breakpoint-sm) {
                        top: 45px;
                        height: 70px;
                    }
                }

                &__name {
                    min-height: 35px;
                    margin-bottom: 24px;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 26px;
                    letter-spacing: -0.32px;
                    color: #FFFFFF;
                    text-transform: uppercase;
                
                    @include is-screen-max($breakpoint-sm) {
                        margin-bottom: 20px;
                        font-size: 21px;
                        line-height: 22px;
                        letter-spacing: -0.27px;
                    }
                }

                &__text {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    letter-spacing: -0.32px;
                    color: #B9B9B9;
                
                    @include is-screen-max($breakpoint-sm) {
                        font-size: 14px;
                        line-height: 22px;
                        letter-spacing: -0.27px;
                    }
                }

                &__bg {
                    position: absolute;
                    bottom: -87px;
                    height: 328px;
                    max-width: initial;
                    max-height: initial;
                    z-index: -1;

                    @include is-screen-max($breakpoint-sm) {
                        bottom: -74px;
                        height: 280px;
                    }
                }

                &:nth-child(1) {
                    .service {
                        &__bg {
                            right: -94px;
                
                            @include is-screen-max($breakpoint-sm) {
                                left: -28px;
                                right: initial;
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    .service {
                        &__bg {
                            right: 0;
                        }
                    }
                }

                &:nth-child(3) {
                    .service {
                        &__bg {
                            left: -127px;
                
                            @include is-screen-max($breakpoint-sm) {
                                left: -108px;
                            }
                        }
                    }
                }

                &:nth-child(4) {
                    .service {
                        &__bg {
                            left: 0;
                        }
                    }
                }

                $colors: #00FF9A, #ECFF00, #DC00FF, #0066FF;
                @for $i from 1 through length($colors) {
                    $color: nth($colors, $i);
                    &:nth-child(#{$i}) {
                        &::before {
                            background: linear-gradient(to bottom, #{$color}, #262626);
                        }
                    }
                }
            }
        }

        &__bg {
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            max-width: initial;
            max-height: initial;
            z-index: -1;
        
            @include is-screen-max($breakpoint-xxl) {
                width: 1920px;
            }
        
            @include is-screen-max($breakpoint-sm) {
                top: 280px;
                transform: translateX(calc(-50% + 25px));
                width: 640px;
                min-width: calc(100vw + 50px);
            }
        }
    }

    .contacts {
        width: 570px;
        max-width: calc(100% - 80px);
    
        @include is-screen-max($breakpoint-sm) {
            width: 400px;
        }

        .formarea {
            margin-top: -10px;
    
            @include is-screen-max($breakpoint-sm) {
                margin-top: 0;
            }
        }
    }
}